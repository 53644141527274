import * as React from 'react'

import { Link } from 'react-router-dom'

import * as styles from './WebViews.scss'
/* tslint:disable no-namespace max-line-length */

const PageContent: React.FC = () => (
  <div>
    <h1>WebViews</h1>
    <p>
      WebViews is a high-level web-application framework written in{' '}
      <a href="https://wiki.haskell.org/Introduction">Haskell</a> and JavaScript, based on ideas and
      experiences that originated from the <Link to="/proxima-2.0">Proxima 2.0 project</Link>.
      WebViews allows the specification of complex web applications through declarative descriptions
      of compositional views that have their own state. These WebViews exist at the server side and
      have an html presentation, which is incrementally kept in-sync with the client-side view in
      the browser. A huge advantage of this high-level compositional approach is that it is not only
      easier to create large web applications, but also to maintain or redesign them.
    </p>
    <p>Key features of WebViews are:</p>
    <ul>
      <li>
        A powerful underlying model, which allows the expression of complex view patterns without
        resorting to primitive code. Examples include tabbed views, sortable list views with
        different categories, and search fields with autocompletion.
      </li>
      <li>Support for sessions and user authentication.</li>
      <li>
        Because WebViews does not rely on cookies, several WebViews apps can exist simultaneously in
        the same browser. These apps can either share a single session or each can have its own.
      </li>
      <li>
        The web-server is incremental, hence an update in a small part of the page (e.g. editing a
        comment in a blog) will not reload the entire page, but only the modified parts.
      </li>
      <li>
        Navigation paths are encoded in the url using hash parameters. This means you can use the
        browser's Back and Forward buttons to navigate within a web application (without reloading
        the entire page,) as well as make bookmarks.
      </li>
      <li>
        Relatively small in size: the library is currently about 4000 lines of Haskell code and
        about 1000 lines of JavaScript and css code.
      </li>
    </ul>
    {/*<p>Haskell: Template Haskell, Generic programming, Several monads, quite a few extensions, Happstack, BlazeHtml</p>*/}
    <p style={{ paddingTop: '10px' }}>
      You can click on the images below to see WebViews in action.
    </p>

    <div className={styles.figureLeft} style={{ marginTop: '23px' }}>
      <Link to="/webviews/webforms">
        <img
          src={require('img/webviews/webforms-sample-589x240.jpg')}
          alt="WebForms sample"
          width="296"
          height="122"
        />
        <div className={styles.caption}>Dynamic form generator</div>
      </Link>
    </div>

    <div className={styles.figureRight}>
      <Link to="/webviews/reservations-app">
        <img
          src={require('img/webviews/reservations-sample-574x320.jpg')}
          alt="Reservations sample"
          width="288"
          height="162"
        />
        <div className={styles.caption}>Reservation app for restaurants</div>
      </Link>
    </div>

    {/*FIX TEXT
<p style='clear:both;padding-top:20px'>
The third example is a ficticious web site called BorrowIt for borrowing items from friends or contacts on the site. The main purpose of this example was to explore how an actual site could be built with WebViews. BorrowIt has several pagesIt shows actual sites can be built navigating, searching sorting.
<a href='https://borrowit.oblomov.com' style='text-decoration:none'>
<div style='display: table;margin:0 auto'>
  <img src={require('img/webviews/BorrowIt.png')} width='XXX' height='160' alt='BorrowIt' style='border: solid black 1px;'/>
  <div style='text-align: center; font-style:italic; margin-top:2px'>BorrowIt</div>
</div></a>

</p>
*/}
  </div>
)
export default PageContent
