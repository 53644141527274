import * as React from 'react'

import { Link } from 'react-router-dom'

import * as styles from './About.scss'

const PageContent: React.FC = () => (
  <div>
    <h1>Oblomov Systems - Martijn Schrage</h1>
    <div itemScope={true} itemType="http://data-vocabulary.org/Person">
      <div className={styles.avatarContainer}>
        <img
          className={styles.avatar}
          src={require('img/about/martijn-164x164.jpg')}
          alt="Martijn Schrage"
          title="Martijn Schrage"
          itemProp="photo"
          width="170"
          height="170"
        />
      </div>

      <p>
        Welcome to the website of <span itemProp="affiliation">Oblomov Systems</span>! My name is{' '}
        <span itemProp="name">Martijn Schrage</span>, and I am a{' '}
        <span itemProp="role">freelance software developer</span> based in{' '}
        <span itemProp="locality">Utrecht</span>,{' '}
        <span itemProp="country-name">The Netherlands</span>. After finishing my PhD, I worked as a
        scientific programmer at Utrecht University for several years, before starting as a
        freelancer.
      </p>
      <p>
        In 2009, I started Oblomov Systems with a grant from the{' '}
        <a href="https://nlnet.nl/">NLnet foundation</a>, for a follow-up software project to my PhD
        project Proxima (<a href={require('pdf/thesis-2004-proxima.pdf')}>thesis.pdf</a>,{' '}
        <a href={require('pdf/thesis-2004-proxima.bib')}>bib</a>). Since then, I have done projects
        for <a href="https://www.uu.nl/">Utrecht University</a>,{' '}
        <a href="https://www.rechtspraak.nl/organisatie-en-contact/organisatie/raad-voor-de-rechtspraak">
          Raad voor de Rechtspraak
        </a>
        , and{' '}
        <a href="https://www.wageningenur.nl/nl/Expertises-Dienstverlening/Onderzoeksinstituten/central-veterinary-institute.htm">
          CVI
        </a>{' '}
        (Central Veterinary Institute), as well as developed an augmented-reality iPhone app called{' '}
        <Link to="/hirise">hiRise</Link>. As of 2014, I'm affiliated with the company{' '}
        <a href="https://www.well-typed.com">Well-Typed</a> as a part-time Haskell consultant.
      </p>
    </div>
    <p>
      I have a passion for creating elegantly-coded software with intuitive user interfaces. My main
      specializations are web applications and scientific programming, and I enjoy solving complex
      problems, preferably by using high-level techniques and languages.
    </p>
    <p>
      Like any self-respecting software developer, I am developing my own web-application framework,
      which is called <Link to="/webviews">WebViews</Link>. It is written in Haskell and JavaScript
      and aims to create complex web applications from declarative specifications. More information
      on WebViews, including live examples, can be found <Link to="/webviews">here</Link>.
    </p>

    <p>A (non-extensive) list of languages I work with:</p>
    <ul>
      <li>
        <a href="https://wiki.haskell.org/Introduction">Haskell</a> (a lazy functional language,
        hence the name <a href="https://en.wikipedia.org/wiki/Oblomov">Oblomov</a>)
      </li>
      <li>TypeScript / JavaScript (React, Redux, Node.js, Angular, Express, JQuery, Lodash, D3)</li>
      <li>Java (Eclipse)</li>
      <li>Html5 / CSS3 (Joomla, K2)</li>
      <li>Objective C (iPhone/iPad)</li>
      <li>PHP</li>
      <li>C#</li>
    </ul>
    <p>
      My <Link to="/portfolio">portfolio</Link> provides an overview of past projects. For a
      complete list, look at my <a href={require('pdf/cv-martijn-schrage-english.pdf')}>C.V.</a>,
      {/* Removed Dutch until it is updated: C.V.(<a href={require('pdf/cv-martijn-schrage-english.pdf')}>English</a> / <a href={require('pdf/cv-martijn-schrage-nederlands.pdf')}>Dutch</a>), */}
      or send an e-mail to martijn
      <span>
        @<span>ob</span>lo<span>mov.</span>com
      </span>
      .
      {/* very basic spam protection (is tricky with server-side rendering, as any expressions will be evaluated) */}
    </p>
  </div>
)
export default PageContent
