import * as React from 'react'
import { useEffect } from 'react'

import * as styles from './AtomListEdit.scss'

/* eslint-disable @typescript-eslint/no-var-requires  */
const atom = require('client/atom-list-edit/atom.coffee')
const ListEdit = require('client/atom-list-edit/list-edit.coffee')
/* eslint-enable @typescript-eslint/no-var-requires  */

declare const window: Window

// Add modifier keys based on platform
const ModifiedKey: React.FC<{ keyChar: string }> = ({ keyChar }) => {
  const isApple =
    global.window && // In server side rendering, just render windows keys
    // This causes a tiny pause before correct bindings
    // are shown, but that's okay as it is far down on the page.
    // TODO: Is there a better way to handle this?
    (window.navigator.platform.startsWith('Mac') || window.navigator.platform.startsWith('iP'))
  return <code suppressHydrationWarning>{`${isApple ? 'alt-cmd' : 'ctrl-shift'}-${keyChar}`}</code>
}

const PageContent: React.FC = () => {
  useEffect(() => {
    ListEdit.activate()
    atom.init()
  }, [])

  return (
    <div>
      <h1>Atom list-edit</h1>
      <p>
        The <a href="https://atom.io/packages/list-edit">list-edit</a> package for the{' '}
        <a href="https://atom.io/">Atom editor</a> provides list-aware cut, copy, and paste
        operations that automatically handle separators and whitespace, while taking into account
        strings and comments. For example, to cut the first element from{' '}
        <code>[Three, One, Two]</code> and paste it at the end:
      </p>
      <table className={styles.editActions}>
        <tbody>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                [T<span className={styles.cursor}></span>hree, One, Two]
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-cut</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                [<span className={styles.cursor}></span>One, Two]
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>→</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                [One, Two<span className={styles.cursor}></span>]
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-paste</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                [One, Two, Three<span className={styles.cursor}></span>]
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        On <code>list-cut</code> the list element that contains the cursor is removed, together with
        its trailing separator and whitespace. After moving the cursor past the last element,{' '}
        <code>list-paste</code> inserts the cut element and puts a separator and whitespace in front
        of it. List-edit also works in a vertical layout and on multiple elements:
      </p>
      <table className={styles.editActions}>
        <tbody>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'} <span className={styles.selection}>p2: 'B'{'\n    '}, p</span>3: 'C'
                {'\n    '}, p1: 'A'{'\n    }'}
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-cut</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'} <span className={styles.cursor}></span>p1: 'A'{'\n    }'} &nbsp;
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>→</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'} p1: 'A'<span className={styles.cursor}></span>
                {'\n    }'} &nbsp;
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-paste</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'} p1: 'A'{'\n    '}, p2: 'B'{'\n    '}, p3: 'C'
                <span className={styles.cursor}></span>
                {'\n    }'}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <p>And even between different lists (with yet another layout):</p>
      <table className={styles.editActions}>
        <tbody>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'}
                {'\n  '}radius: 32,{'\n  '}z:<span className={styles.cursor}></span> 2,{'\n  '}
                pos:{'{\n    '}x: 4,{'\n    '}y: 3{'\n  }'}
                {'\n}'}
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-cut</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'}
                {'\n  '}radius: 32,{'\n  '}
                <span className={styles.cursor}></span>pos:{'{\n    '}x: 4,{'\n    '}y: 3{'\n  }'}
                {'\n}'}
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>→</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'}
                {'\n  '}radius: 32,{'\n  '}pos:{'{\n    '}x: 4,{'\n    '}y: 3{'\n '}
                <span className={styles.cursor}></span>
                {' }'}
                {'\n}'}
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-paste</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                o = {'{'}
                {'\n  '}radius: 32,{'\n  '}pos:{'{\n    '}x: 4,{'\n    '}y: 3,{'\n    '}z: 2
                <span className={styles.cursor}></span>
                {'\n  }'}
                {'\n}'}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Live demo</h3>
      <p>
        The editable area below contains a functional version of the list-edit package (without
        comment/string support). You can try it out by using the keyboard shortcuts below.
      </p>
      <div id="atom-emulator">
        <div className={'notification'}>&nbsp;</div>
        {/* .edit-buffer is also a modular style as it is used for examples, so we need two classes here */}
        <div className={styles.editBuffer + ' edit-buffer'} contentEditable={true}></div>
        {/*NOTE: editBuffer should not contain '\n' linebreaks, only <br/> elements*/}
        <div className={'caption'}>Emulated Atom editor with list-edit package</div>
      </div>
      <h3>Key bindings</h3>
      <table className={styles.keyBindings}>
        <tbody>
          <tr>
            <td>
              <ModifiedKey keyChar="s" />
            </td>
            <td>
              <span className={styles.editAction}>list-select</span>
            </td>
            <td>Select element at cursor, or range of elements in selection</td>
          </tr>
          <tr>
            <td>
              <ModifiedKey keyChar="x" />
            </td>
            <td>
              <span className={styles.editAction}>list-cut</span>
            </td>
            <td>Cut elements (and separator+whitespace) at cursor/selection</td>
          </tr>
          <tr>
            <td>
              <ModifiedKey keyChar="c" />
            </td>
            <td>
              <span className={styles.editAction}>list-copy</span>
            </td>
            <td>Copy elements at cursor/selection to the clipboard</td>
          </tr>
          <tr>
            <td>
              <ModifiedKey keyChar="v" />
            </td>
            <td>
              <span className={styles.editAction}>list-paste</span>
            </td>
            <td>Paste elements (and separator+whitespace) at cursor/selection</td>
          </tr>
        </tbody>
      </table>
      <h3>How selection works</h3>
      <p>
        The edited parent list is the smallest list that contains the entire text selection. To
        select an element, the selection/cursor needs to be inside the element or around it. A text
        selection that contains only a separator or whitespace represents an empty range, which is
        only used for paste.
      </p>
      <p>Some examples should clarify selection:</p>
      <table className={styles.editActions}>
        <tbody>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                [ Blinky, <span className={styles.cursor}></span>Inky, Pinky, Clyde ]
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-select</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                [ Blinky, <span className={styles.cursor}></span>Inky, Pinky, Clyde ]
              </span>
            </td>
            <td className={styles.description}>(Empty range)</td>
          </tr>
          <tr className={styles.spacer}></tr>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                [ Blinky, I<span className={styles.cursor}></span>nky, Pinky, Clyde ]
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-select</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                [ Blinky, <span className={styles.selection}>Inky</span>, Pinky, Clyde ]
              </span>
            </td>
            <td className={styles.description}>(Single element)</td>
          </tr>
          <tr className={styles.spacer}></tr>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                [ Blin<span className={styles.selection}>ky, Inky, Pi</span>nky, Clyde ]
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-select</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                [ <span className={styles.selection}>Blinky, Inky, Pinky</span>, Clyde ]
              </span>
            </td>
            <td className={styles.description}>(Multiple elements)</td>
          </tr>
          <tr className={styles.spacer}></tr>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                [ (0,0), (1,1<span className={styles.selection}>), (2</span>,2), (3,3) ]
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-select</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                [ (0,0), <span className={styles.selection}>(1,1), (2,2)</span>, (3,3) ]
              </span>
            </td>
            <td className={styles.description}>(Selection in nested lists)</td>
          </tr>
          <tr className={styles.spacer}></tr>
          <tr>
            <td>
              <span className={styles.editBuffer}>
                {' '}
                {'{'} x: <span className={styles.cursor}></span>120, y: 14 {'}'}
              </span>
            </td>
            <td>
              <span className={styles.arrowLine}></span>
              <span className={styles.editAction}>list-select</span>
              <span className={styles.arrowHead}></span>
            </td>
            <td>
              <span className={styles.editBuffer}>
                {' '}
                {'{'} <span className={styles.selection}>x: 120</span>, y: 14 {'}'}
              </span>
            </td>
            <td className={styles.description}>(Selection in object)</td>
          </tr>
        </tbody>
      </table>
      <h3>Separator and whitespace handling</h3>
      <p>
        List-edit uses the grammar of the edited file to ignore strings and comments, but list
        detection takes place purely on a lexical level. Currently, <code></code>, <code>[]</code>,
        and <code>()</code> are brackets, and <code>,</code> and <code>;</code> are separators.
      </p>
      <p>
        Any separators and whitespace inserted on list-paste are copies of the first separator in
        the target list, unless this has fewer than two elements (and hence no separators,) in which
        case they are taken from the source list. If the original source list also has fewer than
        two elements, a default separator based on the list brackets is inserted with a single
        space. For <code>[]</code> and <code>()</code> the default separator is <code>,</code>, and
        for <code>{'{}'}</code> it is <code>;</code>.
      </p>
      <p>
        Package on Atom.io: <a href="https://atom.io/packages/list-edit">list-edit</a>, GitHub:{' '}
        <a href="https://github.com/Oblosys/atom-list-edit">github.com/Oblosys/atom-list-edit</a>
      </p>
    </div>
  )
}

export default PageContent
