import * as React from 'react'
import { FC } from 'react'

import { BuildInfo, buildInfo } from 'util/BuildInfo'

import * as styles from './BuildInfoRibbon.scss'

type BuildType = 'dev-server' | 'app-bundle' | 'production'

// TODO: Build-time causes discrepancy between server-side & client rendering
//       Times are different, but for the same date, also the buildTimeStr is different, due to leading 0's
function formatBuildInfo(
  { buildTime, gitInfo: { sha, branch, commitMsg } }: BuildInfo,
  buildType: BuildType,
) {
  const buildTimeStr = buildType === 'dev-server' ? buildTime : '<no build time>'
  // Only set buildTime for dev-server build, as for bundle server, pre-rendered BuildInfoRibbon will contain
  // server-bundle buildTime, whereas client BuildInfoRibbon will contain client-bundle build time, causing react
  // pre-rendering mismatch.
  return (
    (buildType === 'dev-server' ? 'Dev-server launch' : 'Build') +
    ' time: ' +
    buildTimeStr +
    '\n' +
    (branch && sha ? "Branch '" + branch + "' [" + sha + ']:\n' : '') +
    'Commit message:\n' +
    commitMsg
  )
}

const BuildTag: FC<{ buildType: BuildType }> = (props) => (
  <div className={styles.buildInfoRibbon} title={formatBuildInfo(buildInfo, props.buildType)}>
    <div className={styles.buildInfoRibbonContent} data-build-type={props.buildType}>
      {props.buildType}
    </div>
  </div>
)

const BuildInfoRibbon: FC = () =>
  buildInfo.isProduction ? (
    <div></div>
  ) : buildInfo.isDevServer ? (
    <BuildTag buildType="dev-server" />
  ) : (
    <BuildTag buildType="app-bundle" />
  )
export default BuildInfoRibbon
