import * as React from 'react'

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import haskellHighlighter from 'react-syntax-highlighter/dist/cjs/languages/hljs/haskell'
SyntaxHighlighter.registerLanguage('haskell', haskellHighlighter)
import highlightStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/xcode'

const PageContent: React.FC = () => (
  <div>
    <SyntaxHighlighter
      language="haskell"
      style={highlightStyle}
      customStyle={{ fontSize: '12px', overflowX: 'scroll' }}
    >
      {`mainForm = form $ [ introductie, persoonsgegevens, stellingen, deelDrie ] ++ vignettes

introductie = page [ htmlFileElt "Introductie.html" ]

persoonsgegevens = page
  [ htmlElt "<em>Eerst wil ik u enkele algemene vragen stellen, klik op het antwoord dat voor u van toepassing is of vul de betreffende informatie in.</em>"
  , medSkip
  , tableElt "Algemeen"
      [ [ htmlElt "Wat is uw leeftijd?", styleElt "width: 50px" $ textAnswerValidateElt "leeftijd" isNumber ]
      , [ medSkip ]
      , [ htmlElt "Wat is uw geslacht?", styleElt "width: 100px" $ buttonAnswerElt "geslacht" ["Man", "Vrouw"]]
      , [ medSkip ]
      , [ htmlElt "In welke functie bent u momenteel werkzaam?", radioTextAnswerElt "functie" "functieAnders"
                                                                   [ "Activiteitenbegeleider"
                                                                   , "Groepsbegeleider"
                                                                   , "Helpende gezondheidszorg"
                                                                   , "Verpleeghulp"
                                                                   , "Verpleegkundige"
                                                                   , "Verzorgende"
                                                                   , "Anders, nl. :" ] ]
      , [ medSkip ]
      , [ htmlElt "Wat is uw hoogst afgeronde opleiding?", radioTextAnswerElt "opleiding" "opleidingAnders"
                                                             [ "Lager algemeen onderwijs (basisonderwijs)"
                                                             , "Lager beroepsonderwijs (LTS, LEAO)"
                                                             , "Middelbaar algemeen onderwijs (MAVO, MULO, VMBO)"
                                                             , "Middelbaar beroepsonderwijs (MTS, MEAO, MBO)"
                                                             , "Voortgezet algemeen onderwijs (HAVO,VWO, Atheneum, Gymnasium)"
                                                             , "Hoger beroepsonderwijs (HBO, HEAO, HTS)"
                                                             , "Wetenschappelijk onderwijs"
                                                             , "Anders, nl. :" ] ]
      ]
  ]

stellingen = page
  [ htmlElt "<em>Het tweede deel van de vragenlijst bevat twaalf stellingen die betrekking hebben op uw persoonlijke situatie in uw huidige werk. Wilt u aangeven in hoeverre u het eens bent met de stellingen hieronder door het cijfer aan te klikken. Hoe hoger het cijfer, des te beter u zich kunt vinden in de stelling.</em>"
  , bigSkip
  , tableElt "Stellingen"
      [ [ htmlElt "", htmlElt "Mee eens<span style='margin-left:50px'></span>Mee oneens" ]
      , mkScaleQuestion 7 "presteren" "Ik vind het belangrijk om beter te presteren dan mijn collega's"
      , mkScaleQuestion 7 "angst" "Mijn angst om op mijn werk onder te presteren is vaak wat mij motiveert"
      , mkScaleQuestion 7 "vermijden" "Ik wil vooral vermijden dat ik onderpresteer op mijn werk"
      , mkScaleQuestion 7 "vergelijking" "Ik vind het belangrijk om goed te presteren, in vergelijking met mijn directe collega's"
      ]
  ]

deelDrie = page [ htmlFileElt "DeelDrie.html" ]

vignettes =
  [ mkVignettePage Vignette { nummer = 1
                            , omschr1 = "Een app waarmee u rapporten mondeling kunt inspreken, die achteraf door andere medewerkers schriftelijk kunnen worden vastgelegd"
                            , omschr2 = "Een app waarmee u snel kunt zien welke medicijnen met elkaar interacteren"
                            , uitproberen1 = "Mogelijk"
                            , uitproberen2 = "Niet mogelijk"
                            , klaar1 = "Onvoldoende"
                            , klaar2 = "Goed"
                            , succes1 = "Goed"
                            , succes2 = "Onvoldoende"
                            , collegas1 = "Sceptisch"
                            , collegas2 = "Enthousiast"
                            , beloning1 = "Tijdbesparing"
                            , beloning2 = "Minder kans op fouten"
                            }
  , mkVignettePage Vignette { nummer = 2
                            , omschr1 = "Een app waarmee u snel kunt zien welke medicijnen met elkaar interacteren"
                            , omschr2 = "Een app waarmee u patiëntgegevens als een zakkaartje op uw iPhone meedraagt"
                            , uitproberen1 = "Niet mogelijk"
                            , uitproberen2 = "Mogelijk"
                            , klaar1 = "Onvoldoende"
                            , klaar2 = "Onvoldoende"
                            , succes1 = "Goed"
                            , succes2 = "Onvoldoende"
                            , collegas1 = "Sceptisch"
                            , collegas2 = "Sceptisch"
                            , beloning1 = "Minder kans op fouten"
                            , beloning2 = "Niet meer zeulen met dossiers"
                            }
  ]


-- Create a table row containing a question that has n numbered answer buttons.
mkScaleQuestion :: Int -> String -> String -> TableRow
mkScaleQuestion n tag question = [ htmlElt question, buttonAnswerElt tag $ map show [1..n] ]

data Vignette = Vignette { nummer :: Int
                         , omschr1, omschr2, uitproberen1, uitproberen2, klaar1, klaar2, succes1, succes2
                         , collegas1, collegas2, beloning1, beloning2 :: String
                         }

-- Create a form page for vignette vt.
mkVignettePage :: Vignette -> FormPage
mkVignettePage vt = page $
  [ tableElt "VignetteOmschr"
    [ [ htmlElt $ "Vignette "++show (nummer vt), htmlElt "Situatie 1", htmlElt "Situatie 2"]
    , [ htmlElt "<div >Omschrijving van de app</div>", htmlElt $ "<div >"++omschr1 vt++"</div>"
                                                     , htmlElt $ "<div >"++omschr2 vt++"</div>"]
    , [ htmlElt "Uitproberen", htmlElt $ uitproberen1 vt, htmlElt $ uitproberen2 vt]
    , [ htmlElt "De mate waarin de organisatie technisch klaar is om de app in te voeren", htmlElt $ klaar1 vt, htmlElt $ klaar2 vt]
    , [ htmlElt "De mate waarin de organisatie in het verleden succesvol technische innovaties heeft ingevoerd", htmlElt $ succes1 vt, htmlElt $ succes2 vt]
    , [ htmlElt "Mening van uw collega's", htmlElt $ collegas1 vt, htmlElt $ collegas2 vt]
    , [ htmlElt "Beloning", htmlElt $ beloning1 vt, htmlElt $ beloning2 vt] ]
  , medSkip
  , htmlElt $ "<br/><em>Vragen (kruis de situatie aan die het beste bij u past):</em><br/><br/>"
  , tableElt "VignetteVragen"
    [ [ htmlElt "De app die het meest gemakkelijk te gebruiken voor mij als persoon is"
      , buttonAnswerElt ("vignette"++show (nummer vt)++".gemak") ["App 1", "App 2"]]
    , [ htmlElt "De app die het meest nuttig ter ondersteuning van mijn dagelijkse werkzaamheden"
      , buttonAnswerElt ("vignette"++show (nummer vt)++".nut") ["App 1", "App 2"]]
    , [ htmlElt "De app die ik zou gebruiken is"
      , buttonAnswerElt ("vignette"++show (nummer vt)++".voorkeur") ["App 1", "App 2"]]
    ]
  , medSkip
  , htmlElt $ "<br/><em>Klik op het cijfer dat aangeeft in hoeverre u het eens bent met onderstaande stelling:</em><br/><br/>"
  , tableElt "VignetteKiezen"
     [ mkScaleQuestion 10 ("vignette"++show (nummer vt)++".moeilijkKiezen") "Ik vond het moeilijk om te kiezen" ]
  ]`}
    </SyntaxHighlighter>
  </div>
)
export default PageContent
