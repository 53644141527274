import * as React from 'react'

import * as styles from './Instructions.scss'

const PageContent: React.FC = () => (
  <div>
    <h1>Instructions</h1>
    <p>
      <img
        src={require('img/hirise/instructions-map.png')}
        alt="screenshot 1"
        style={{ margin: '10px 20px 0px 0px', border: '1px', borderStyle: 'solid', float: 'left' }}
        width="211"
        height="302"
      />
    </p>
    <div style={{ lineHeight: '26px', marginTop: '100px' }}>
      <b style={{ fontSize: '28px' }}>1</b>&nbsp; Go outside and drag the ruler{' '}
      <img
        src={require('img/hirise/ruler.png')}
        style={{ height: '24pt', verticalAlign: '-4pt' }}
      />{' '}
      on the map next to the object you wish to measure. If GPS is inaccurate, you can use the GPS
      switch <img src={require('img/hirise/button-gps-on.png')} className={styles.button} /> to
      disable it, and drag the user{' '}
      <img src={require('img/hirise/user.png')} className={styles.annotation} /> to your current
      location. Press the play button{' '}
      <img src={require('img/hirise/button-play.png')} className={styles.button} /> to go to the
      camera view.
    </div>

    <p>
      <img
        src={require('img/hirise/instructions-viewing-dom.png')}
        alt="screenshot 2"
        style={{ margin: '90px 0px 0px 20px', border: '1px', borderStyle: 'solid', float: 'right' }}
        width="202"
        height="302"
      />
    </p>
    <div style={{ lineHeight: '26px', marginTop: '140px' }}>
      <b style={{ fontSize: '28px' }}>2</b>&nbsp; Aim the camera in the direction of your object.
      The scale of the ruler corresponds to the size of the object. If necessary, place the ruler in
      front of you with the arrow button{' '}
      <img src={require('img/hirise/button-arrow-round.png')} className={styles.button} /> (on
      iPhone 4), or press the arrow switch{' '}
      <img src={require('img/hirise/button-arrow-switch-on.png')} className={styles.button} /> to
      keep the ruler fixed in front of you (on iPhone 3gs). You can also touch the screen and drag
      to adjust the ruler's direction. Note that this does not affect its distance! Press the pause
      button <img src={require('img/hirise/button-pause.png')} className={styles.button} /> to
      measure the object's height, or return to the map with the map button{' '}
      <img src={require('img/hirise/button-map.png')} className={styles.button} />.
    </div>

    <p>
      <img
        src={require('img/hirise/instructions-measuring-dom.png')}
        alt="screenshot 3"
        style={{ margin: '25px 20px 0px 0px', border: '1px', borderStyle: 'solid', float: 'left' }}
        width="202"
        height="302"
      />
    </p>
    <div style={{ lineHeight: '26px', marginTop: '105px' }}>
      <b style={{ fontSize: '28px' }}>3</b>&nbsp; While paused, touch the screen and drag to move
      the horizontal bar and see its height. You can take a screenshot by simultaneously pressing
      the home and power buttons on your device. Return to the map or camera views with{' '}
      <img src={require('img/hirise/button-map.png')} className={styles.button} /> or{' '}
      <img src={require('img/hirise/button-play.png')} className={styles.button} />.
    </div>
    <div style={{ marginTop: '140px' }}>
      <b>DISCLAIMER</b>&nbsp; Please note that hiRise is intended for indicative measurements only.
      Although the utmost care has been taken to ensure that the underlying computations are
      correct, inaccuracies in sensor or map data, as well as possible programming errors may cause
      inaccurate results. In other words, don't use hiRise to check the length of your bungee cord!
    </div>
  </div>
)
export default PageContent
