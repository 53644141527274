import * as React from 'react'

import { App } from 'components/App'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { buildInfo } from 'util/BuildInfo'

import './GlobalElts.scss' // Global styles on html elements

const container = document.getElementById('app')

if (container !== null) {
  if (!buildInfo.isDevServer) {
    hydrateRoot(container, <App />)
  } else {
    createRoot(container).render(<App />)
  }
} else {
  throw new Error('React root element not found.')
}
