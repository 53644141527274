import * as React from 'react'

import * as styles from './Contact.scss'

const PageContent: React.FC = () => (
  <div>
    <h1>Contact</h1>
    <div className={styles.details}>
      <a className="hidden-xs" href={require('pdf/business-card-cutout.pdf')}>
        <img
          className={styles.businessCard}
          src={require('img/contact/business-card-cutout-332x502.png')}
          alt="Business card"
        />
      </a>
      <div className={styles.description}>Oblomov Systems, Front-end & Haskell development</div>
      dr. Martijn M. Schrage
      <br />
      martijn
      <span>
        @<span>ob</span>lo<span>mov.</span>com
      </span>
      <br />{' '}
      {/* very basic spam protection (is tricky with server-side rendering, as any expressions will be evaluated) */}
      <br />
      KvK: 30250575
      <br />
      <br />
      LinkedIn: <a href="https://www.linkedin.com/in/oblosys/">oblosys</a>
      <br />
      GitHub: <a href="https://github.com/Oblosys">Oblosys</a>
      <br />
      Stack Overflow: <a href="https://stackoverflow.com/users/5770132/oblosys">oblosys</a>
      <br />
      <a href={require('pdf/cv-martijn-schrage-english.pdf')}>C.V.</a>
      {/* Removed Dutch until it is updated: C.V. (<a href={require('pdf/cv-martijn-schrage-english.pdf')}>English</a> / <a href={require('pdf/cv-martijn-schrage-nederlands.pdf')}>Dutch</a>) */}
    </div>
  </div>
)
export default PageContent
