import * as React from 'react'

import { Link } from 'react-router-dom'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import haskellHighlighter from 'react-syntax-highlighter/dist/cjs/languages/hljs/haskell'
SyntaxHighlighter.registerLanguage('haskell', haskellHighlighter)
import highlightStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/xcode'

import * as styles from './WebFormsSample.scss'

const PageContent: React.FC = () => (
  <div>
    <h1>WebForms</h1>

    <p>
      WebForms is an experiment in using WebViews as a form generator. Given a textual specification
      of a form, a web application for that form is automatically generated. The current WebForms
      system is a basic example, written in only a couple of days, but it can already generate an
      interesting range of forms. The size of the generator is about 600 lines of Haskell code, plus
      an additional 180 lines of JavaScript and css code. It should run on all major browsers and
      platforms as well as on mobile devices.
    </p>

    <p>
      Below is a sample form app that you can try out. The blue circles to the right indicate
      progress and turn red when entered text is not valid. The next-page button (labeled
      'Volgende') is enabled once the page has been completely filled out. The entire form is
      specified in 115 lines of Haskell (<Link to="/webviews/webforms/webforms-code">source</Link>)
      and about 100 lines of css code (<Link to="/webviews/webforms/webforms-css">source</Link>) for
      describing table borders and colors. If your browser does not handle the iframe well, you can
      open a{' '}
      <a href="https://forms.oblomov.com" target="_blank" rel="noreferrer">
        non-embedded version
      </a>
      .{' '}
      {/* iframe cannot use redirect url because redirection adds an X-Frame-Options SameOrigin header */}{' '}
      {/*<div className={styles.wvFrame} style='background-color: white; color: red'><div style='padding:20px'>ERROR WHEN WEBVIEW IS OFFLINE.</div></div>*/}
    </p>

    <iframe className={styles.wvFrame} src="https://forms.oblomov.com/#embedded?p=2">
      <a href="https://forms.oblomov.com/#embedded?p=2">
        You are using a very old browser that does not support iframes. Click here to go directly to
        included content.
      </a>
    </iframe>
    <div className={styles.caption}>A live form example</div>

    <p>
      For simplicity, the form has been implemented as a single instance, which means that all users
      will view and edit the same form. For a more serious application, a multi-user form can be
      easily implemented using the built-in user-management functionality.
    </p>

    <h3>Specifying a form</h3>
    <p>
      The <Link to="/webviews/webforms/webforms-code">source code</Link> is straightforward Haskell
      code that constructs the form using the following functions (also called <em>combinators</em>
      ):
    </p>

    <SyntaxHighlighter language="haskell" style={highlightStyle}>
      {`form :: [FormPage] -> WebForm
page :: [FormElt] -> FormPage
tableElt :: String -> [[FormElt]] -> FormElt
htmlElt :: String -> FormElt
textAnswerElt :: QuestionTag -> FormElt
radioAnswerElt :: QuestionTag -> [String] -> FormElt
buttonAnswerElt :: QuestionTag -> [String] -> FormElt
radioTextAnswerElt :: QuestionTag -> QuestionTag -> [String] -> FormElt
styleElt :: String -> FormElt -> FormElt`}
    </SyntaxHighlighter>

    <p>
      The root of the form is a definition <code>mainForm = form [page_1, page_2 ... page_n]</code>{' '}
      that puts the pages of the form together. Each <code>page</code> takes a list of{' '}
      <code>FormElts</code>, which are either an html fragment, an answer widget, or a nested table
      whose cells are again <code>FormElts</code>. Html fragments are constructed with{' '}
      <code>htmlElt</code>, and answer widgets are constructed with <code>textAnswerElt</code>,{' '}
      <code>radioAnswerElt</code>, or <code>buttonAnswerElt</code>. Each of the answer elements has
      a string tag that determines its column in a csv file containing the results. The hybrid
      combinator <code>RadioTextAnswerElt</code> is a radio widget that has a textual alternative at
      the bottom. It has two tags: one for the radio answer and one for the text (which may be
      empty). In order to style the form, css styles can be provided with the <code>styleElt</code>{' '}
      combinator, or put in a separate css file, since the tables have a tag that is added to their
      html class.
    </p>
    <p>
      For textual answers, there is an special combinator that supports validation of the answer:
    </p>

    <SyntaxHighlighter language="haskell" style={highlightStyle}>
      {`textAnswerValidateElt :: QuestionTag -> (String -> Bool) -> FormElt`}
    </SyntaxHighlighter>

    <p>
      This combinator takes an extra function argument of type <code>(String -&gt; Bool)</code> to
      validate its answer and color the progress marker either blue or red. In the example above,
      the answer for the age field ('leeftijd' in Dutch) is specified with:{' '}
      <code>textAnswerValidateElt 'leeftijd' isNumber</code> where <code>isNumber</code> is the
      validator function (which is defined as <code>isNumber = all isDigit</code>). The validation
      is currently server-side only, but could be extended to the client by either providing a
      JavaScript validation function or compiling the Haskell validator to JavaScript.
    </p>
    <p>
      Because the form description is actually a Haskell program, common patterns can be expressed
      using functions. For example, we can create a question that has numbered buttons as its
      possible answers with the following function:
    </p>

    <SyntaxHighlighter language="haskell" style={highlightStyle}>
      {`mkScaleQuestion :: Int -> String -> String -> TableRow
mkScaleQuestion n tag question =
  [ htmlElt question, buttonAnswerElt tag $ map show [1..n] ]`}
    </SyntaxHighlighter>

    <p>
      Now we can use <code>mkScaleQuestion 10 'moeilijk' 'Ik vond het moeilijk om te kiezen'</code>{' '}
      to get the following line in the form:
    </p>

    <img
      className={styles.screenshot}
      src={require('img/webviews/webforms-scale-question-668x49.png')}
      alt="WebForms scale question"
      width="668"
      height="49"
    />

    <p>
      Another example of this abstraction are the vignettes on page 5 and 6 of the example form. The
      structure as well as most text on these pages is constant. Instead of building each page by
      hand, we can define a function <code>mkVignettePage</code> (see{' '}
      <Link to="/webviews/webforms/webforms-code">source</Link>), which takes a record that contains
      only those part that are different and constructs a form page for it. This not only makes it
      much easier to add or modify vignette pages, but also makes it possible to restyle all of them
      in a single place. Moreover, it guarantees that all vignettes have exactly the same format.
    </p>
  </div>
)
export default PageContent
