import * as React from 'react'
import { PropsWithChildren, createContext, useContext } from 'react'

export interface StaticContext {
  statusCode: number
}

const StaticReactContext = createContext<StaticContext | null>(null)

export const StaticProvider: React.FC<PropsWithChildren<{ value: StaticContext | null }>> = ({
  children,
  value,
}) => <StaticReactContext.Provider value={value}>{children}</StaticReactContext.Provider>

export const useStaticContext = () => useContext(StaticReactContext)
