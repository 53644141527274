declare const BUILD_INFO: BuildInfo // global variable defined by webpack DefinePlugin

export interface BuildInfo {
  isProduction: boolean
  isDevServer: boolean
  buildTime: string
  gitInfo: {
    sha: string
    branch: string
    commitMsg: string
  }
}

export const buildInfo: BuildInfo = BUILD_INFO
