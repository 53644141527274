import * as React from 'react'

import { Link } from 'react-router-dom'

import * as styles from './Portfolio.scss'

const PageContent: React.FC = () => (
  <div>
    <h1>Portfolio of Martijn Schrage</h1>
    <p>
      Below is a selection of projects I have worked on in the past few years. For a complete
      overview, see my <a href={require('pdf/cv-martijn-schrage-english.pdf')}>C.V.</a>.
      {/* Removed Dutch until it is updated: C.V. (<a href={require('pdf/cv-martijn-schrage-english.pdf')}>English</a> / <a href={require('pdf/cv-martijn-schrage-nederlands.pdf')}>Dutch</a>). */}
    </p>

    <h2>
      React lifecycle visualizer npm package
      <a
        className={styles.screenshotRight}
        href="https://github.com/Oblosys/react-lifecycle-visualizer#readme"
      >
        <img
          src={require('img/portfolio/react-lifecycle-visualizer-small-502x361.jpg')}
          alt="react-lifecycle-visualizer"
          width="252"
          height="182"
        />
      </a>
    </h2>
    <p>
      Inspired by StackOverflow questions about React's new lifecycle methods, I've create a package
      for easily tracing and visualizing the lifecycle of arbitrary components. The{' '}
      <code>traceLifecycle</code> HOC traces a component and causes its lifecycle methods to show up
      in an animated log. A{' '}
      <a href="https://stackblitz.com/edit/react-lifecycle-visualizer?file=index.js">
        StackBlitz playground
      </a>{' '}
      is available to experiment with new (and old) lifecycle methods.
    </p>
    <p>
      GitHub:{' '}
      <a href="https://github.com/Oblosys/react-lifecycle-visualizer#readme">
        Oblosys/react-lifecycle-visualizer
      </a>{' '}
      (1200+ stars)
      <br />
      npm:{' '}
      <a href="https://www.npmjs.com/package/react-lifecycle-visualizer">
        <code>react-lifecycle-visualizer</code>
      </a>
      <br />
    </p>
    <p>
      <b>Update:</b> React-lifecycle-visualizer has made the list of{' '}
      <a href="https://medium.mybridge.co/amazing-react-js-open-source-of-the-year-v-2019-364d057ac3f3">
        amazing React open-source tools of 2018
      </a>{' '}
      by Mybridge, was chosen tool of the year 2018 by the{' '}
      <a href="https://react.statuscode.com/issues/119">React Status blog</a>, and was listed number
      5 in this list of{' '}
      <a href="https://dev.to/jsmanifest/22-miraculous-tools-for-react-developers-in-2019-4i46">
        22 miraculous tools for React developers in 2019
      </a>
      !
    </p>

    <h2>
      Interimreview.nl website
      <a className={styles.screenshotLeft} href="https://www.interimreview.nl">
        <img
          src={require('img/portfolio/interimreview-screenshot-small-412x318.jpg')}
          alt="interimreview.nl"
          width="208"
          height="161"
        />
      </a>
    </h2>
    <p>
      For the consultancy agency <a href="http://veranderkracht.nl/">Veranderkracht</a>, I built a
      review website for interim professionals. The website was initially built using a MEAN stack
      with TypeScript only for the server, but in a subsequent project the Angular front-end has
      been rewritten to React/Redux and TypeScript. Though still a prototype, the site features
      permission-based authentication, autonomous user registration with e-mail verification,
      paginated search, and a responsive design. The implementation of the RESTful api makes heavy
      use of Bluebird promises, and Mongoose for typed MongoDB queries.
    </p>
    <p>
      url: <a href="https://www.interimreview.nl">https://www.interimreview.nl</a>
    </p>

    <h2>List-edit package for Atom</h2>
    <p>
      List-edit is a package for the Atom editor that I wrote in CoffeeScript. For more information
      and a live demo, see the <Link to="/atom-list-edit">List-edit page</Link> on this site.
    </p>

    <h2>
      RFID positioning of museum visitors
      <a className={styles.screenshotRight} href={require('img/portfolio/lucy-replay-15s.gif')}>
        <img
          src={require('img/portfolio/lucy-small-542x320.jpg')}
          alt="Lucy"
          width="273"
          height="162"
        />
        <div className={styles.caption}>Click for animation (7.3Mb)</div>
      </a>
    </h2>
    <p>
      The Cercando project was a cooperation between the{' '}
      <a href="http://www.hetnatuurhistorisch.nl/">Natural History Museum Rotterdam</a>,{' '}
      <a href="http://www.rug.nl/">Rijksuniversiteit Groningen</a>, and several companies. The goal
      of the project was to use real-time RFID positioning to gather information on how museum
      visitors move through an exhibition, as well as provide interaction with the exhibition
      through lighting, sound, and information terminals. I was hired to figure out how to determine
      the position of a visitor carrying an RFID tag from the strength of the antenna signals and
      build the software to compute, record and visualize these positions.
    </p>
    <p>
      The scope of the project ranged from physical experiments to determine the RFID signal
      characteristics to creating <a href="https://d3js.org/">D3</a> visualizations of visitor
      movement. This <a href={require('img/portfolio/lucy-architecture-2244x1647.jpg')}>image</a>{' '}
      shows an overview of the architecture. A multi-threaded buffered Java server handles the
      low-level communication with the RFID readers and relays the real-time signal strength data to
      a Node.js/TypeScript web server. The web server computes and stores the actual visitor
      positions. To compute the positions, I had to develop an incremental trilateration algorithm,
      as ordinary trilateration does not gracefully degrade in the presence of distortions, and gets
      tricky for more than three antennas.
    </p>
    <p>
      Despite the fact that the antennas were not ideally placed and the entire exhibition was made
      out of metal (which reflects RFID signals), the system worked surprisingly well. This{' '}
      <a href={require('img/portfolio/lucy-replay-15s.gif')}>animation (7.3Mb)</a> from the first
      field test shows four small groups of school children walking around on the exhibition floor.
      The system is currently operational in the exhibition{' '}
      <a href="http://www.hetnatuurhistorisch.nl/exposities/pure-veerkracht/pettentocht.html">
        Pure Veerkracht
      </a>{' '}
      at the Natural History Museum Rotterdam.
    </p>
    <p>
      All code is available on <a href="https://github.com/Oblosys/Cercando">GitHub</a>, but due to
      the limited budget and rather ambitious scope of the project most of the code never made it
      out of the proof-of-concept state.
    </p>

    <h2>
      JavaScript restaurant reservations
      <a className={styles.screenshotLeft} href="http://node-reservations.oblomov.com">
        <img
          src={require('img/portfolio/restaurant-small-259x376.jpg')}
          alt="Restaurant reservations"
          width="132"
          height="190"
        />
      </a>
    </h2>
    <p>
      To polish up my JavaScript skills, I implemented a{' '}
      <a href="http://node-reservations.oblomov.com">
        small web application for managing reservations at a restaurant
      </a>
      . Though small, the application is surprisingly complete. It consists of a calendar page for
      viewing and managing reservations, as well as a page that clients can use to make new
      reservations. Everything is updated in real time, so if a client considers making a
      reservation on a certain day, and a time slot on that day is filled by someone else, the
      corresponding button in the client app is immediately disabled. The implementation uses the
      JQuery, Backbone, Underscore, Node.js, Express, and Socket.io libraries, and has a generic
      storage layer that can use either MySQL or JSON. The source code is available on{' '}
      <a href="https://github.com/Oblosys/restaurant-reservations">github</a>.
    </p>

    <h2>
      Raad voor de Rechtspraak
      <a
        className={styles.screenshotRight}
        href="https://www.rechtspraak.nl/organisatie-en-contact/organisatie/raad-voor-de-rechtspraak"
      >
        <img
          src={require('img/portfolio/rechtspraak-logo-153x82.gif')}
          alt="Rechtspraak logo"
          width="155"
          height="84"
        />
      </a>
    </h2>
    <p>
      For the Dutch council for the Judiciary (
      <a href="https://www.rechtspraak.nl/organisatie-en-contact/organisatie/raad-voor-de-rechtspraak">
        Raad voor de Rechtspraak
      </a>
      ), I have worked on the 'Koppelvlak' project (not the infamous GPS project), which was
      concerned with standardizing data exchange between the large number of applications used by
      the council. The <a href="https://github.com/AmpersandTarski">Ampersand</a> system was used to
      formalize the exact format of this data exchange.
    </p>
    <p>
      Based on a <a href="https://en.wikipedia.org/wiki/Relation_algebra">relation-algebra</a> model
      (not to be confused with{' '}
      <a href="https://en.wikipedia.org/wiki/Relational_algebra">relational algebra</a>), Ampersand
      generates a functional specification (in the form of a pdf document) and a database. The
      Haskell part of my work was to fix and extend the existing Ampersand language and system so it
      could handle the models required for the Koppelvlak project. The other part consisted of
      creating a new web front-end for manipulating the generated databases. The front-end needed to
      allow users to browse and edit data, while making sure that the rules specified in the model
      were not violated. Simultaneous updates from other editing sessions were signaled real-time.
      The implementation consisted of a Haskell code generator with PHP server code and client-side
      JavaScript. {/*collect edits and map onto db updates*/}
    </p>

    <h2>
      hiRise iPhone app
      <a
        className={styles.screenshotRight}
        href={require('img/portfolio/hirise-measuring-dom-640x960.jpg')}
      >
        <img
          src={require('img/portfolio/hirise-measuring-dom-small-210x315.jpg')}
          alt="hiRise"
          width="107"
          height="160"
        />
      </a>
    </h2>
    <p>
      As a personal project, I have developed <Link to="/hirise/">hiRise</Link>, an
      augmented-reality iPhone app for measuring the height of buildings. Because no
      augmented-reality libraries were availabe at the time, I had to implement all functionality
      from scratch. A large part of the effort went into dealing with inaccurate and, in some cases,
      slow sensors in the phone. Because the gyroscope tends to drift over time, it needed to be
      stabilized by with data from the much slower and more noisy compass. The resulting
      augmented-reality rendering turned out quite responsive and fluid.
    </p>
    {/*<ul>
<li>GPS</li>
<li>User interaction</li>
<li>Dynamic help system</li>
</ul>
*/}

    <h2>
      ALIVE project
      <a
        className={styles.screenshotLeft}
        href={require('img/portfolio/alive-psd-editor-802x402.png')}
      >
        <img
          src={require('img/portfolio/alive-psd-editor-small-438x220.jpg')}
          alt="PSD Editor"
          width="221"
          height="112"
        />
      </a>
    </h2>
    <p>
      Utrecht University hired me as a scientific Java programmer for the European research project
      ALIVE, which was concerned with creating a framework for using model-driven design and agent
      technology for creating flexible service-oriented systems. I worked mainly on the{' '}
      <a href="http://www.cs.uu.nl/research/projects/opera/">Operetta</a> toolkit, developed in
      Utrecht. {/*mention buggy Eclipse?*/}
    </p>
    <p>
      Part of the work consisted of improving the buggy existing code base and integrating it with
      other tools of the project. I also designed and implemented several graph editors, using the
      Eclipse EMF and GMF frameworks, as well as a textual formula editor, which provided syntax
      coloring and showed the formula structure while editing.
    </p>

    <h2>
      Piglet 2.0
      <a className={styles.screenshotRight} href={require('img/portfolio/piglet-2.0-767x848.png')}>
        <img
          src={require('img/portfolio/piglet-2.0-small-340x376.jpg')}
          alt="Piglet 2.0"
          width="172"
          height="190"
        />
      </a>
    </h2>
    <p>
      For a research project regarding the early detection of swine fever the{' '}
      <a href="https://www.wur.nl/nl/Expertises-Dienstverlening/Onderzoeksinstituten/Bioveterinary-Research.htm">
        Central Veterinary Institute
      </a>{' '}
      hired me to develop a web application for collecting data. Vets in several countries needed a
      web interface to submit data they gathered from visits to pig farms. The application need to
      support user management and multiple languages. A complicating factor in this application was
      that the answers to certain questions could affect whether other questions were enabled or
      not. For example, only if a pig had been treated with antibiotics, a question about
      effectiveness of the treatment would be enabled. In some cases these dependencies spread
      across chains of several questions.
    </p>
    <p>
      The client side for Piglet 2.0 was implemented in JavaScript with JQuery, and the server was
      written in Haskell, since this was before libraries like Node.js existed.
    </p>

    <h2>
      Dazzle
      <a
        className={styles.screenshotLeft}
        href={require('img/portfolio/dazzle-screenshot-971x769.jpg')}
      >
        <img
          src={require('img/portfolio/dazzle-screenshot-small-404x320.jpg')}
          alt="Dazzle"
          width="202"
          height="160"
        />
      </a>
    </h2>
    <p>
      Between my PhD and Oblomov Systems, I spent four years at Utrecht University as a scientific
      programmer for the Decision Support Systems group. My main task was the development of the
      Bayesian network toolkit <a href="http://foswiki.cs.uu.nl/foswiki/Dazzle/WebHome">Dazzle</a>,
      which is written in Haskell. Besides Haskell development, the work also included the
      development of a Pocket PC application for data collection by vets in the field. This
      application, called Piglet, was written in C#. Piglet served as the base for the international
      web-based Piglet 2.0.
    </p>
    <p>
      An interesting aspect of the work on Dazzle was that algorithms usually came directly from
      research, in the form of high-level mathematical specifications with little attention for
      implementation strategies. We also put a lot of effort in designing the user interfaces of the
      subtools to make them as intuitive and dynamic as possible.
    </p>
    <p>
      Even though Haskell may not have seemed an obvious choice for implementing a statistical
      toolkit with a graphical user interface, the project was quite successful. Dazzle hardly has
      any bugs, has sufficient performance, and the code base is about 5 to 10 times smaller than
      comparable toolkits written in Lisp or Java. Some insights that we had during the development
      are discussed in the Haskell Symposium paper{' '}
      <a href={require('pdf/haskell-symposium-2005-dazzle.pdf')}>
        'Haskell ready to Dazzle the real world'
      </a>
      .
      {/*<ul>
  <li>Implemented algorithms Lauritzen, Sensitivity, etc.</li>
  <li>Comparable to .. but 10 times smaller + faster, etc.</li>
</ul>*/}
    </p>
  </div>
)
export default PageContent
/*

*/
