import * as React from 'react'

// import * as styles from './Support.scss';

const PageContent: React.FC = () => (
  <div>
    <h1>Troubleshooting</h1>
    <p>If the measured height appears to be wrong, check the possible causes below.</p>
    <ul style={{ marginTop: '0.3em', paddingLeft: '1.1em' }}>
      <li>
        Make sure the camera is at the camera height set with the slider below. Also take care not
        to raise the phone when looking up!
      </li>
      <li>
        Check that you are at the position specified on the map, as GPS may have been inaccurate.
        Furthermore, the camera view assumes you stay in one position, so you cannot walk around.
      </li>
      <li>
        Make sure the base of the measured object is at the same altitude as you; hiRise assumes a
        flat landscape.
      </li>
      <li>
        Keep in mind that dragging the ruler in the camera view only affects its direction, not its
        distance.
      </li>
      <li>
        When you are too far away from your object, hiRise is less accurate. Get close enough for
        your object to fill at least about a third of the screen.
      </li>
      <li>
        If the ruler makes a lot of horizontal jumps, move away from metal objects to reduce
        magnetic interference.
      </li>
      <li>
        On iPhone 3gs, the accelerometers may need to be calibrated. (If this is the case, the ruler
        may appear to lean to one side.) To calibrate the device, press the calibration button in
        the troubleshooting section of the info screen.
      </li>
    </ul>
  </div>
)
export default PageContent
