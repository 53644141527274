import * as React from 'react'

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import cssHighlighter from 'react-syntax-highlighter/dist/cjs/languages/hljs/css'
SyntaxHighlighter.registerLanguage('css', cssHighlighter)
import highlightStyle from 'react-syntax-highlighter/dist/cjs/styles/hljs/xcode'

const PageContent: React.FC = () => (
  <div>
    <SyntaxHighlighter language="haskell" style={highlightStyle} customStyle={{ fontSize: '12px' }}>
      {`.FormTable > tbody > tr > td {
  padding: 3px 5px 3px 5px;
}
.TableAlgemeen {
}

.TableStellingen {
  border-width: 1px;
  border-color: black;
  border-style: solid;
}
.TableStellingen .LeftCol {
  border-width: 1px;
  vertical-align: middle;
  border-left-style: solid;
  border-left-color: black;
  border-right-style: solid;
  border-right-color: black;
}
.TableStellingen .RightCol {
  border-width: 1px;
  vertical-align: middle;
  border-right-style: solid;
  border-right-color: black;
}
.TableStellingen .TopRow {
  border-bottom-style: solid;
  border-bottom-color: #eee;
}
.TableStellingen .MiddleRow {
  border-bottom-style: solid;
  border-bottom-color: #eee;
}
.TableStellingen tr:nth-child(even) {
  background-color: #f8f8f8;
}


.TableVignetteOmschr td {
  border-color: black;
  border-width: 2px;
  border-style: none;
}
.TableVignetteOmschr .LeftCol {
  border-color: black;
  background-color: #eee;
  border-right-style: solid;
  border-left-style: none;
}
.TableVignetteOmschr .MiddleCol {
  width: 215px;
  text-align: center;
}
.TableVignetteOmschr .RightCol {
  width: 215px;
  text-align: center;
}

.TableVignetteOmschr .TopRow.LeftCol {
  border-color: black;
  border-style: none solid solid none;
  background-color: #fff;
}
.TableVignetteOmschr .TopRow.MiddleCol {
  border-style: solid solid none none;
  background-color: #eee;
}
.TableVignetteOmschr .TopRow.RightCol {
  border-color: black;
  border-style: solid solid solid none;
  background-color: #eee;
}
.TableVignetteOmschr .MiddleRow {
  border-bottom-color: #ddd;
  border-right-color: black;
  border-style: solid;
}
.TableVignetteOmschr .BottomRow {
  border-bottom-color: black;
  border-style: solid;
}

.TableVignetteVragen {
  border-width: 1px;
  border-color: black;
  border-style: solid;
}
.TableVignetteVragen .LeftCol {
  vertical-align: middle;
}
.TableVignetteVragen .RightCol {
  width: 120px;
  vertical-align: middle;
}
.TableVignetteVragen .MiddleRow {
  border-width: 1px;
  border-color: #ddd;
  border-bottom-style: solid;
  border-top-style: solid;
}

.TableVignetteKiezen .LeftCol {
  vertical-align: middle;
}`}
    </SyntaxHighlighter>
  </div>
)
export default PageContent
