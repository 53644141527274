import * as React from 'react'
import { FC, useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import * as styles from './Navbar.scss'

interface ItemBase {
  label: string
}

export interface Item extends ItemBase {
  type: 'item'
  target: { path: string } | { href: string }
}

export interface Submenu extends ItemBase {
  type: 'submenu'
  target: { path: string } // Only local pages can have a submenu.
  subItems: readonly Item[] // No nested Submenus.
}

export type NavbarItem = Item | Submenu

export const Navbar = (props: { navbarItems: readonly NavbarItem[] }) => {
  const [isHamburgerActive, setIsHamburgerActive] = useState(false)
  const closeHamburgerMenu = () => setIsHamburgerActive(false)

  return (
    <div>
      <div className={styles.contentVerticalSpacer}></div>
      <div className={styles.topMarginFiller}></div>
      <div className={styles.navbar}>
        <div className={styles.flexibleHorizontalSpacer}></div>
        <div className={styles.navbarLogo}></div>
        <div className={styles.collapsableContainer} data-is-hamburger-active={isHamburgerActive}>
          <div className={styles.navItems}>
            {props.navbarItems.map((item) => (
              <NavItem key={item.label} item={item} closeHamburgerMenu={closeHamburgerMenu} />
            ))}
          </div>
        </div>
        <div className={styles.navbarLogoSpacer}></div>
        <div className={styles.flexibleHorizontalSpacer}></div>
        <HamburgerButton isActive={isHamburgerActive} setIsActive={setIsHamburgerActive} />
      </div>
    </div>
  )
}

interface NavItemProps {
  item: NavbarItem
  closeHamburgerMenu: () => void
}

const NavItem: FC<NavItemProps> = ({ item, closeHamburgerMenu }) => {
  const target = item.target
  const label = item.label

  const location = useLocation()
  const currentPath = location.pathname === '/' ? '/' : location.pathname.replace(/\/$/, '')
  const navigate = useNavigate()
  const navigateAndClose = (path: string) => {
    closeHamburgerMenu()
    navigate(path)
  }

  return (
    <div className={styles.navItemContainer}>
      {'path' in target ? (
        <div
          className={styles.navItem}
          onClick={() => navigateAndClose(target.path)}
          data-active={currentPath === target.path}
          data-child-active={item.type === 'submenu' && currentPath.startsWith(target.path)}
        >
          <a>
            <span>{label}</span>
          </a>
        </div>
      ) : (
        // href items cannot be active, nor can their children.
        <div className={styles.navItem} data-active={false} data-child-active={false}>
          <a href={target.href}>
            <span>{label}</span>
          </a>
        </div>
      )}
      {item.type === 'submenu' && (
        <NavSubmenu items={item.subItems} closeHamburgerMenu={closeHamburgerMenu} />
      )}
    </div>
  )
}

interface NavDropdownProps {
  items: readonly Item[]
  closeHamburgerMenu: () => void
}

const NavSubmenu: FC<NavDropdownProps> = ({ items, closeHamburgerMenu }) => (
  <div className={styles.navbarDropdown}>
    <div className={styles.navbarDropdownItems}>
      {items.map((item) => (
        <NavItem key={item.label} item={item} closeHamburgerMenu={closeHamburgerMenu} />
      ))}
    </div>
  </div>
)

interface HamburgerButtonProps {
  isActive: boolean
  setIsActive: (isActive: boolean) => void
}
const HamburgerButton: FC<HamburgerButtonProps> = ({ isActive, setIsActive }) => (
  <div
    className={styles.hamburgerButton}
    data-active={isActive}
    onClick={() => setIsActive(!isActive)}
  >
    <div className={styles.hamburgerLine}></div>
    <div className={styles.hamburgerLine}></div>
    <div className={styles.hamburgerLine}></div>
  </div>
)
