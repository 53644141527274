import * as React from 'react'

// import * as styles from './HiRise.scss';

const PageContent: React.FC = () => (
  <div>
    <h1>hiRise</h1>
    <div style={{ float: 'right', position: 'relative', marginTop: '20px', marginLeft: '40px' }}>
      <img
        src={require('img/hirise/iphone-running-hirise.png')}
        alt="hRise screenshot"
        style={{ marginBottom: '0px' }}
        width="162"
        height="350"
      />
      {/*<a href='http://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=411509671&amp,mt=8'> <img src={require('img/hirise/Download_on_the_App_Store_Badge_US-UK_135x40.svg')} alt='Available on the App Store' style={{display: block, position: relative, top: '0px', left: '13px'}} height='40'/></a>*/}
    </div>
    <p>
      hiRise is an augmented-reality app for the iPhone that allows you to measure the height of
      buildings and other tall structures by looking through your camera. Simply point out the
      object you want to measure on a map, and see a real-time virtual ruler in the camera feed.
    </p>
    <p>
      Rather than a static photograph, hiRise employs augmented reality to create an animated ruler
      that appears to be part of the landscape. When you move the camera view, the ruler moves
      accordingly.
    </p>
    {/*  NOTE: when changing this, also adapt top margin for image and marginTop for Features. */}

    {/* <p>hiRise can be downloaded from the <a href='http://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=411509671&amp,mt=8'>App Store</a>.</p> */}

    <p>
      <img
        src={require('img/hirise/itunes-artwork.png')}
        alt="hiRise"
        style={{ margin: '20px 35px 20px 0px', float: 'left' }}
        width="130"
        height="130"
      />
    </p>
    <h2 style={{ marginTop: '22px' }}>Features:</h2>
    <ul style={{ marginTop: '0.5em', paddingLeft: '1em', overflow: 'hidden' }}>
      <li>Animated virtual ruler in the camera feed</li>
      <li>Uses the gyroscope and/or the accelerometers</li>
      <li>For more accurate measurements, the camera feed may be paused</li>
      <li>Support for meters and feet</li>
    </ul>
    {/* </a> <a href='http://itunes'>hiRise in the App Store</a> */}
    {/* http://itunes.apple.com/linkmaker/ or rightclick on product page, as specified in AppStoreMarketingGuidelines.pdf */}
  </div>
)
export default PageContent
