import * as React from 'react'

import * as styles from './Proxima-2.0.scss'

const PageContent: React.FC = () => (
  <div>
    <h1>Proxima 2.0</h1>
    <p>
      In 2009, Oblomov Systems started the Proxima 2.0 project. The aim of Proxima 2.0 is to develop
      a client-server architecture, interfaces, and new technology, which will enable offering the
      functionality of Proxima-based solutions through web pages. For a more detailed description of
      the project, see the{' '}
      <a href={require('pdf/nlnet-proxima-2.0-proposal.pdf')}>project proposal</a>.
    </p>
    <p>
      The Proxima 2.0 project was supported by a grant from the{' '}
      <a href="https://nlnet.nl/">NLnet foundation</a>. Logistic support by the{' '}
      <a href="https://www.uu.nl/en/research/software-systems/software-technology">
        Center for Software Technology
      </a>{' '}
      at Utrecht University is gratefully acknowledged.
    </p>
    <div className={styles.screenshot}>
      <a href="https://github.com/Oblosys/proxima/wiki">
        <img
          src={require('img/proxima-2.0/helium-editor-screenshot-550x438.png')}
          alt="Proxima 2.0 Helium editor"
          width="550"
          height="438"
        />
      </a>
    </div>
    <p>
      The <a href="https://github.com/Oblosys/proxima/wiki">Proxima GitHub wiki</a> contains more
      information, including a{' '}
      <a href="https://github.com/Oblosys/proxima/wiki/Gallery">gallery of live demos</a>.
    </p>
  </div>
)
export default PageContent
