import * as React from 'react'

import * as styles from './Page.scss'
import Sidebar from './Sidebar'
import * as sidebarStyles from './Sidebar.scss'

const Page: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div>
    <Sidebar />
    <div className={styles.page}>
      <div className={styles.hSpacer}></div>
      <div className={sidebarStyles.sidebarSpaceLeft}></div>
      <div className={styles.pageContent}>
        {children}
        <Footer />
      </div>
      <div className={sidebarStyles.sidebarSpaceRight}></div>
      <div className={styles.hSpacer}></div>
    </div>
  </div>
)
export default Page

const Footer: React.FC = () => (
  <div className={styles.footer}>
    <div className={styles.footerLine} />
    <div className={styles.copyright}>content & design &copy; 2023 Oblomov Systems</div>
  </div>
)
