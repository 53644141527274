import * as React from 'react'

import * as styles from './ReservationsApp.scss'

const PageContent: React.FC = () => (
  <div>
    <h1>Reservations app</h1>

    <p>
      Web applications for making reservations often do not make full use of the dynamic
      possibilities of current web browsers. It is not uncommon that you have to fill out the entire
      reservation before you find out that the desired time slots are not available. A friendlier
      approach is to show the available times dynamically, by disallowing a reservation to be made
      for unavailable times.
    </p>
    <p>
      The two web applications on this page form a simplified reservation system, which consists of
      a calendar app for the restaurant and a reservation app for the client. The example was built
      to experiment with the integration of client-side scripting and static server-side
      presentations in WebViews. The JavaScript ensures that browsing in the calendar is responsive
      enough and that changes in the reservation database are shown in the client app. The
      server-side Haskell on the other hand allows for an elegant specification of the calendar
      algorithms.
    </p>
    <p>
      The sample is below live, so feel free to make or cancel some reservations. If your browser
      does not handle the iframe well, you can open the non-embedded version of the{' '}
      <a href="https://reservations.oblomov.com/#restaurant" target="_blank" rel="noreferrer">
        calendar
      </a>{' '}
      or the{' '}
      <a href="https://reservations.oblomov.com/#client" target="_blank" rel="noreferrer">
        client
      </a>
      .{' '}
      {/* iframe cannot use redirect url because redirection adds an X-Frame-Options SameOrigin header */}
    </p>

    <div className={styles.restaurant}>
      {/*<div className={styles.wvFrameRestaurant roundedCorners} style='background-color: white; color: red'><div style='padding:20px'>Due to a server disk crash on May 16, the WebViews demos are currently unavailable. This will be fixed as soon as possible.</div></div>*/}
      <iframe
        className={styles.restaurantFrame}
        src="https://reservations.oblomov.com/#embeddedRestaurant"
      >
        <a href="https://reservations.oblomov.com">
          You are using a very old browser that does not support iframes. Click here to go directly
          to included content.
        </a>
      </iframe>
      <div className={styles.caption}>Calendar app for the restaurant</div>
    </div>

    <div className={styles.client}>
      {/*<div className={styles.wvFrameClient roundedCorners} style='background-color: white; color: red'><div style='padding:20px'>Due to a server disk crash on May 16, the WebViews demos are currently unavailable. This will be fixed as soon as possible.</div></div>*/}
      <iframe className={styles.clientFrame} src="https://reservations.oblomov.com/#embeddedClient">
        <a href="https://reservations.oblomov.com">
          You are using a very old browser that does not support iframes. Click here to go directly
          to included content.
        </a>
      </iframe>
      <div className={styles.caption}>Reservation app for the client</div>
    </div>

    <p style={{ clear: 'both' }}>
      The app on the left-hand side is a basic calendar for browsing and canceling reservations. It
      would typically run on a tablet (use this{' '}
      <a href="https://reservations.oblomov.com/#restaurant">link</a> for a non-embedded version).
      The blue numbers in each day cell show the number of reservations, followed by the total
      number of people in parentheses. When a day is selected, the times in the bottom row (18h -
      24h) can be selected to list the reservations for the corresponding hour. The maximum number
      of people for each half-hour time slot is 12.
    </p>

    <p>
      On the right-hand side is the app for making reservations, which is designed to run on a
      mobile phone (use this <a href="https://reservations.oblomov.com/#client">link</a>). If you
      select the number of people and a date, only the available times for that time are enabled.
    </p>

    <p>
      The reservation system handles multiple simultaneous users and updates dynamically. Hence,
      newly made reservations will show up in the calendar, and will also grey out time selection
      buttons in the reservation app if the current reservation would exceed the maximum of 12
      people. You can test this by setting up a reservation for 8 people and selecting a date and
      time, but not pressing Confirm. Then, open a{' '}
      <a href="https://reservations.oblomov.com/#client" target="_blank" rel="noreferrer">
        separate reservation app
      </a>{' '}
      and set up and confirm another 8-person reservation for that same date and time. After a
      couple of seconds, the chosen time button in the first reservation will be deselected and
      disabled. If the new reservation is deleted in the calendar, the time button becomes available
      again.
    </p>
  </div>
)
/*


*/
export default PageContent
