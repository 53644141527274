import * as React from 'react'
import { useEffect, useRef } from 'react'

import BuildInfoRibbon from 'components/BuildInfoRibbon'
import Page from 'components/Page'
import About from 'components/pages/About'
import AtomListEdit from 'components/pages/AtomListEdit'
import Contact from 'components/pages/Contact'
import Portfolio from 'components/pages/Portfolio'
import Proxima2_0 from 'components/pages/Proxima-2.0'
import HiRise from 'components/pages/hirise/HiRise'
import Instructions from 'components/pages/hirise/Instructions'
import Support from 'components/pages/hirise/Support'
import ReservationsApp from 'components/pages/webviews/ReservationsApp'
import WebFormsSample from 'components/pages/webviews/WebFormsSample'
import WebViews from 'components/pages/webviews/WebViews'
import WebFormsCode from 'components/pages/webviews/webforms/WebFormsCode'
import WebFormsCss from 'components/pages/webviews/webforms/WebFormsCss'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

import { Navbar, NavbarItem } from './Navbar'
import { StaticContext, StaticProvider, useStaticContext } from './StaticContext'

export const App: React.FC = () => (
  <BrowserRouter>
    <AppContent staticContext={null} />
  </BrowserRouter>
)

export const AppContent: React.FC<{ staticContext: StaticContext | null }> = ({
  staticContext,
}) => {
  const { pathname } = useLocation()

  const isInitialPathRef = useRef(true)
  useEffect(() => {
    if (!isInitialPathRef.current) {
      fetch('/api/navigate?path=' + encodeURIComponent(pathname))
    }
    isInitialPathRef.current = false
  }, [pathname])

  return (
    <StaticProvider value={staticContext}>
      <BuildInfoRibbon />
      <Navbar navbarItems={navbarItems} />
      <Page>
        <Routes>
          {/* Note: Redirect from /webviews to /webviews/ is quirky (also matches on not found), maybe because
                        `exact` ignores optional trailing '/'? Instead, we just use /webviews, and have LeftMenu
                        be able to handle /webviews without the trailing slash.
              */}
          <Route path="/" element={<About />} />
          <Route path="/portfolio/" element={<Portfolio />} />
          <Route path="/atom-list-edit" element={<AtomListEdit />} />
          <Route path="/proxima-2.0" element={<Proxima2_0 />} />
          <Route path="/webviews" element={<WebViews />} />
          <Route path="/webviews/webforms" element={<WebFormsSample />} />
          <Route path="/webviews/webforms/webforms-code" element={<WebFormsCode />} />
          <Route path="/webviews/webforms/webforms-css" element={<WebFormsCss />} />
          <Route path="/webviews/reservations-app" element={<ReservationsApp />} />
          <Route path="/hirise" element={<HiRise />} />
          <Route path="/hirise/instructions" element={<Instructions />} />
          <Route path="/hirise/support" element={<Support />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Page>
    </StaticProvider>
  )
}

const PageNotFound = () => {
  const staticContext = useStaticContext()

  if (staticContext) {
    staticContext.statusCode = 404 // so server can set res.status to 404
  }

  const location = useLocation()
  return (
    <div>
      <h1>Page not found</h1>
      Invalid path: <code>{location.pathname}</code>
    </div>
  )
}

const navbarItems: NavbarItem[] = [
  { type: 'item', label: 'ABOUT', target: { path: '/' } },
  { type: 'item', label: 'PORTFOLIO', target: { path: '/portfolio' } },
  { type: 'item', label: 'ATOM LIST-EDIT', target: { path: '/atom-list-edit' } },
  {
    type: 'submenu',
    label: 'WEBVIEWS',
    target: { path: '/webviews' },
    subItems: [
      { type: 'item', label: 'WEBFORMS', target: { path: '/webviews/webforms' } },
      { type: 'item', label: 'RESERVATIONS APP', target: { path: '/webviews/reservations-app' } },
    ],
  },
  { type: 'item', label: 'PROXIMA 2.0', target: { path: '/proxima-2.0' } },
  { type: 'item', label: 'CONTACT', target: { path: '/contact' } },
]

const trimSlashes = (str: string) => str.replace(/^\/+/, '').replace(/\/+$/, '')

export const pathHasSidebar = (path: string) => {
  const rootPath = trimSlashes(path).split('/')?.[0]
  const topLevelSubmenuPaths = navbarItems.flatMap(({ type, target }) =>
    type === 'submenu' ? [trimSlashes(target.path)] : [],
  )

  return topLevelSubmenuPaths.includes(trimSlashes(rootPath))
}
