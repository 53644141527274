import * as React from 'react'

import { Link, Route, Routes, useLocation } from 'react-router-dom'

import * as styles from './Sidebar.scss'

// NOTE: <Route path='*' element={<ul></ul>}/> is necessary to prevent 'No routes matched location ..' warnings.
const Sidebar: React.FC = () => (
  <div className={styles.sidebarContainer}>
    <div className={styles.hSpacer}></div>
    <div className={styles.sidebar}>
      <Routes>
        <Route path="/webviews/*" element={<WebViewsSubMenu />} />
        <Route path="/hirise/*" element={<HiRiseSubMenu />} />
        <Route path="*" element={<ul></ul>} />
      </Routes>
    </div>
    <div className={styles.pageSpace}></div>
    <div className={styles.sidebarSpaceRight}></div>
    <div className={styles.hSpacer}></div>
  </div>
)
export default Sidebar

const WebViewsSubMenu: React.FC = () => {
  const location = useLocation()
  return (
    <ul>
      <SubMenuLink label="WEBVIEWS" path="/webviews" url={location.pathname} />
      <SubMenuLink label="WEBFORMS" path="/webviews/webforms" url={location.pathname} />
      <SubMenuLink
        label="RESERVATIONS APP"
        path="/webviews/reservations-app"
        url={location.pathname}
      />
    </ul>
  )
}

const HiRiseSubMenu: React.FC = () => {
  const location = useLocation()
  return (
    <ul>
      <SubMenuLink label="HIRISE" path="/hirise" url={location.pathname} />
      <SubMenuLink label="INSTRUCTIONS" path="/hirise/instructions" url={location.pathname} />
      <SubMenuLink label="SUPPORT" path="/hirise/support" url={location.pathname} />
    </ul>
  )
}

// props.url may or may not have a trailing slash
const SubMenuLink = (props: { label: string; path: string; url: string }) => (
  <li data-is-active={props.url === props.path || props.url === props.path + '/'}>
    <Link to={props.path}>{props.label}</Link>
  </li>
)
